import React from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link, NavLink } from 'react-router-dom';
import '../assets/css/dashboard.css';
import axios from 'axios';
import SidebarOne from "../components/sidebar1";
import InnerHeader from "../components/InnerHeader";
import CanvasJS from '@canvasjs/charts';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    api_url:localStorage.getItem('api_url'),
    data:[], 
    data1:[],
    data2:[],
    data3:[]           
  };     
  
}
componentDidMount() {   
  
  axios({
    method: 'get',
    url: this.state.api_url+'/getCounts',                
    
  }).then(res => {
    const data = res.data;
   // console.log(data);
    this.setState({                         
            data: data.counts,             
          });
  });


  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsuser',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data1: data1.counts1,             
          });
  });

  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsStores',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data2: data1.counts1,             
          });
  });


  axios({
    method: 'get',
    url: this.state.api_url+'/getCountsAgents',                
    
  }).then(res => {
    const data1 = res.data;
   // console.log(data);
    this.setState({                         
            data3: data1.counts1,             
          });
  });

}

renderChart() {
  var chart = new CanvasJS.Chart("chartContainer", {
    title: {
      text: "Basic Column Chart in JavaScript"
    },
    data: [{
      type: "column",
      dataPoints: [
        { label: "apple", y: 10 },
        { label: "orange", y: 15 },
        { label: "banana", y: 25 },
        { label: "mango", y: 30 },
        { label: "grape", y: 28 }
      ]
    }]
  });

  // Render Chart
  chart.render();
}

render() {
  return (
    <div className="dashboard">
          <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div className='dashboard_hash' style={{'margin-left':'20%','backgroundColor':'#F9F9FC'}}>
              <p>Dashboard</p>

              <div className='dashboard_layouts' >
                    <div className="col-md-4">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">User Management</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">0</div>
                            <div className="sales-today-3 iact">Inactive</div>
                            </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Stores</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data2.storesc}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data2.storesc}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">0</div>
                            <div className="sales-today-3 iact">Inactive</div>
                            </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Agents</div>
                          <div class="row" style={{marginTop:"20px"}}>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">{this.state.data3.agentc}</div>
                            <div className="sales-today-3">Total</div>
                            </div>
                            <div class="col-md-4 lineimg">
                            <div className="sales-today-copy-5">{this.state.data3.agentc}</div>
                            <div className="sales-today-3 act">Active</div>
                            </div>
                            <div class="col-md-4">
                            <div className="sales-today-copy-5">0</div>
                            <div className="sales-today-3 iact">Inactive</div>
                            </div>
                        </div>
                      </div>
                    </div> */}

              </div>

            <div className='dash_charts'>
                  <div className="col-md-6">
                            <div className="overlap-group-6">
                              <div className="sales-today-5">Revenue Generated</div>
                              <div id="chartContainer">
                              </div>

                              
                            </div>
                  </div>

                  <div className="col-md-6">
                            <div className="overlap-group-6">
                              <div className="sales-today-5">Top 10 Regions - Sales($'s)</div>
                            </div>
                  </div>
            </div>

            <div className='dash_chart1'>
                <div className="col-md-12">
                      <div className="overlap-group-6">
                        <div className="sales-today-5">Top 10 products by Sales</div>
                        
                      </div>
                </div>
            </div>
              
                
        </div>
    </div>
  );
}

}

export default Dashboard;



