import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContainerSelection = () => {
  const [containers, setContainers] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const fetchContainersAndSeats = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const theatre_id = localStorage.getItem('theatre_id');
        const screen_id = localStorage.getItem('screen_id');
        console.log(theatre_id);
        console.log(screen_id);
        const response = await axios.get(api_url + '/containers/'+theatre_id+'/'+screen_id);
        setContainers(response.data.data);
      } catch (error) {
        console.error('Error fetching containers or seats:', error);
      }
    };
    fetchContainersAndSeats();
  }, []);

  const handleSeatSelection = (containerId, seatId, price) => {
    const isSelected = selectedSeats.some(seat => seat.seatId === seatId);
    const numericPrice = Number(price); // Ensure price is treated as a number
  
    if (isSelected) {
      // If the seat is already selected, remove it and decrease the total price
      setSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.seatId !== seatId));
      setTotalPrice(prevTotalPrice => prevTotalPrice - numericPrice); // Subtract the numeric price
    } else {
      // If the seat is not selected, add it and increase the total price
      setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, { containerId, seatId }]);
      setTotalPrice(prevTotalPrice => prevTotalPrice + numericPrice); // Add the numeric price
    }
  };
  

  const handleBookNow = () => {
    if (selectedSeats.length === 0) {
      alert('Please select at least one seat.');
      return;
    }

    // Proceed to payment gateway or reservation confirmation
    console.log('Selected Seats:', selectedSeats);
    console.log('Total Price:', totalPrice);
  };

  return (
    <div>
      <div className="container-list">
        {containers.map((container) => (
          <div key={container._id} className="container-item">
            <p>{container.container_name} - ₹{container.price}</p>
            <div className="seats-grid">
              {container.seats && container.seats.length > 0 ? (
                container.seats.map((seat) => (
                  <div
                    key={seat._id}
                    className={`seat ${selectedSeats.some(s => s.seatId === seat._id) ? 'selected' : ''} ${seat.isBooked ? 'booked' : ''}`}
                    onClick={() => !seat.isBooked && handleSeatSelection(container._id, seat._id, container.price)}
                  > 
                    {seat.seat_no}
                  </div>
                ))
              ) : (
                <p>No seats available for this container</p>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="booking-summary">
  <p>Total Price: ₹{totalPrice}</p> {/* Rupee symbol for currency */}
  <button onClick={handleBookNow}>Book Now</button>
</div>
    </div>
  );
};

export default ContainerSelection;
