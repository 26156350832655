import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';


// import "../assets/css/product.css"; // Import custom CSS file for styling

const DatatableAssociateProducts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url');
      const sub_cat_id = localStorage.getItem("sub_cat_id");
      const admin_id = localStorage.getItem('admin_id');
      const res = await axios.get(api_url + '/admingetProducts2/'+sub_cat_id+'/'+admin_id);
      const data = await res.data.products;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };

  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );

    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = a[sortField].toString().toLowerCase();
        const fieldB = b[sortField].toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setFilteredData(filteredItems);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.selector);
    setSortDirection(sortDirection);
    filterData(filterText, column.selector, sortDirection);
  };


  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const navigate = useNavigate();

 const handleDivClick = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/viewProduct", { replace: true });
  };

  const handleDivClick1 = () => {
    navigate("/createProduct", { replace: true });
  };

  
  const exportSelectedRows = () => {
    const selectedRowsData = selectedRows.map(row => {
      return {
        'Item ID': row.item_id,
        'SKU': row.item_sku,
        'Barcode Value': row.barcode_value,
        'Title': row.product_name,
        'List Price': '₹' + row.price + '.00',
        'Offer Price': '₹' + row.price + '.00',
        'Inventory on Hand': row.quantity
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "products.xlsx");
  };

  const exportSelectedRows1 = () => {
    console.log('ddd');
    setOpenModal(true); // Open the modal
  };

  const handleModalClose = () => {
    setOpenModal(false); // Close the modal
  };

  const handleFileUpload =(e) =>{
    // this.setState({
    //     pic:e.target.files[0]
    // });
    document.getElementById("fileID").click();
  }
  const fileUploadclick=(e) => {
   
    setPic(e.target.files[0]);
          setFilename(e.target.files[0].name);
          
    
      console.log("file");
      console.log(pic);
      //document.getElementById("uploadForm").style.display = "block";
  }
  const handleClearSelection = () => {
    setSelectedRows([]);
  };

  const handleSelectProducts = () => {
    const selectedIds = selectedRows.map(row => row.id);
    const api_url = localStorage.getItem('api_url');
    const sub_cat_id = localStorage.getItem("sub_cat_id");

    axios({
      method: "post",
      url: api_url + "/updateassociateproducts",
      data: {
        sub_cat_id: sub_cat_id,
        selectedIds: selectedIds
      }
    })
      .then((res) => {
        alert("Products associated successfully");
        // navigate("/Products", { replace: true });
        window.location.reload();

      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });

  };


  const web_url = localStorage.getItem('web_url');

  const columns = [
    {
        name: 'Check Box',
        cell: (row) => (
          <input
            type="checkbox"
            onChange={(e) => handleRowSelected(e.target.checked, row)}
            checked={selectedRows.map((selectedRow) => selectedRow.id).includes(row.id)}
            disabled={row.act_sts !== 1} // Disable checkbox if act_sts is not 1
          />
        ),
    },
    {
      name: 'Item ID',
      selector: row => row.item_id,
      sortable: true,
    },
    {
      name: 'SKU',
      selector: row => row.item_sku,
      sortable: true,
    },
    {
      name: 'Barcode Value',
      selector: row => row.barcode_value,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.product_name,
      sortable: true,
    },
    {
      name: 'List Price',
      selector: row => '₹'+row.price+'.00',
      sortable: true,
    },
    {
      name: 'Offer Price',
      selector: row => '₹'+row.price+'.00',
      sortable: true,
    },
    {
      name: 'Inventory on Hand',
      selector: row => row.quantity,
      sortable: true,
    }
  ];

  return (
    <div>
        <div className="pro_buttons2">
            
           <input type="text" className="item_input_ass" placeholder="Search Products" />
            <div className="select_brand" style={{'display':'flex'}}>
              
               <select  className="item_input_ass1" value="">
                <option>Select Brand</option>
               </select>
            </div>

            <div className="select_manu" style={{'display':'flex'}}>
               <select className="item_input_ass2" value="">
                <option>Select Manufacturer</option>
               </select>
            </div>

            <p className="clear_check" onClick={handleClearSelection}><b>Clear / Deselect All</b></p>

            </div>
        <p className="produc_de">Products</p>
    <div className="data_margin">
      
      <DataTable
  columns={columns}
  data={filteredData}
  pagination
  paginationPerPage={15}
  paginationRowsPerPageOptions={[15, 20, 25,30,50,100,200,500,1000]}
  highlightOnHover
  onSort={handleSort}
  sortServer
  selectableRows
  selectableRowsHighlight
  selectableRowsNoSelectAll
  selectableRowsVisibleOnly
  customStyles={{
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#f3f3f3",
        },
      },
    },
  }}
/>
    </div>
        <br></br>
          <div className="select_products">
            <button className="selected_pro" onClick={handleSelectProducts}>Select Products</button>
          </div>   
        <br></br>
    </div>
  );
};

export default DatatableAssociateProducts;
