import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';


// import "../assets/css/product.css"; // Import custom CSS file for styling

const DatatableProducts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [allSelected, setAllSelected] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url');
      const admin_id = localStorage.getItem('admin_id');
      const res = await axios.get(api_url + '/admingetProducts/'+admin_id);
      const data = await res.data.products;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };

  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
    filterData(filterText, column.selector, direction);
  };
  
  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key] &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  
    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = (a[sortField] || "").toString().toLowerCase();
        const fieldB = (b[sortField] || "").toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }
  
    setFilteredData(filteredItems);
  };
  

  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const navigate = useNavigate();

//   const handleDivClick = (cat_id) => {
//     localStorage.setItem("category_id", cat_id);
//     navigate("/Product", { replace: true });
//   };

 const handleDivClick = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/viewProduct", { replace: true });
    // this.props.history.push('/viewProduct');
  };

  const handleDivClick1 = () => {
    navigate("/createProduct", { replace: true });
    // this.props.history.push('/viewProduct');
  };
  const handleDivClick3 = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/addImages", { replace: true });
  };


  
  const exportSelectedRows = () => {
    console.log('ddd');
    const selectedRowsData = selectedRows.map(row => {
      return {
        // 'Item ID': row.item_id,
        // 'SKU': row.item_sku,
        // 'Barcode Value': row.barcode_value,
        'Title': row.product_name,
        'List Price': '₹' + row.price + '.00',
        'Offer Price': '₹' + row.price + '.00',
        'Inventory on Hand': row.quantity
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "products.xlsx");
  };

  const exportSelectedRows1 = () => {
    console.log('ddd');
    setOpenModal(true); // Open the modal
  };

  const handleDivClick2 = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/editProduct", { replace: true });
    // this.props.history.push('/viewProduct');
  };

  const handleModalClose = () => {
    setOpenModal(false); // Close the modal
  };

  const handleFileUpload =(e) =>{
    // this.setState({
    //     pic:e.target.files[0]
    // });
    document.getElementById("fileID").click();
  }
  const fileUploadclick = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;

    if (fileType !== "text/csv" && fileType !== "application/vnd.ms-excel" && !file.name.endsWith(".csv")) {
      setErrorMessage("Only CSV files are allowed.");
      setPic(null);
      setFilename("");
    } else {
      setErrorMessage("");
      setPic(file);
      setFilename(file.name);
    }
  }

 const handleImport=() => {
    let errors = {};
    var agentimp = new FormData();
    agentimp.append('pic', pic); 
    const api_url = localStorage.getItem('api_url');
    const admin_id = localStorage.getItem('admin_id');
    agentimp.append('admin_id', admin_id);

    axios({
        method: 'post',
        url:api_url+'/importProducts',   
        data: agentimp
             
    }).then(res => {
      alert("Product details imported successfully");
        navigate("/Products", { replace: true });
        window.location.reload();
        })
  }

  const customHeader = (column) => {
    if (column.filter) {
      return (
        <input
          type="text"
          placeholder={`Filter ${column.name}`}
          onChange={(e) => handleFilterChange(e, column.selector)}
          value={filterText}
          style={{ width: "100%" }} // Adjust the width as needed
        />
      );
    }
    return null;
  };

  const web_url = localStorage.getItem('web_url');

  const columns = [
    {
        name:  <input
      type="checkbox"
      onChange={(e) => handleSelectAll(e.target.checked)}
      checked={allSelected}
    />,
      cell: (row) => (
        <input
  type="checkbox"
  onChange={(e) => handleRowSelected(e.target.checked, row)}
  checked={selectedRows.map((selectedRow) => selectedRow.id).includes(row.id)}
/>
      ),
    },
    // {
    //   name: 'Item ID',
    //   selector: (row) => (row && row.item_id) || '',
    //   sortable: true,
    //   filter: true,
    // },
    // {
    //   name: 'SKU',
    //   // selector: row => row.item_sku,
    //   selector: (row) => (row && row.item_sku) || '',
    //   sortable: true,
    //   filter: true,
    // },
    // {
    //   name: 'Barcode Value',
    //   // selector: row => row.barcode_value,
    //   selector: (row) => (row && row.barcode_value) || '',
    //   sortable: true,
    //   filter: true,
    // },
    {
      name: 'Title',
      // selector: row => row.product_name,
      selector: (row) => (row && row.product_name) || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'List Price',
      // selector: row => '₹'+row.price+'.00',
      selector: (row) => (row && '₹'+row.price+'.00') || '',

      sortable: true,
      filter: true,
    },
    {
      name: 'Offer Price',
      // selector: row => '₹'+row.price+'.00',
      selector: (row) => (row && '₹'+row.price+'.00') || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'Inventory on Hand',
      // selector: row => row.quantity,
      selector: (row) => (row && row.quantity) || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'Action',
      selector: row => row.action,
      cell: (row) => (
        <div>
        <button
          className="tableBtn"
          onClick={() => handleDivClick(row.id)}
        >
          View
        </button>
         <button
         className="tableBtn"
         onClick={() => handleDivClick2(row.id)}
       >
         Edit
       </button>
       <button className="tableBtn" onClick={() => handleDivClick3(row.id)}>Images</button>
        </div>
       
      ),
    },
  ];

  return (
    <div>
        <div className="pro_buttons">
            
            <button className="import_tem" onClick={exportSelectedRows1}>IMPORT</button>
            <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
            <button className="create_tem" onClick={handleDivClick1} >CREATE</button>
            </div>
        <p className="produc_de">Products</p>
    <div className="data_margin">
      
    <DataTable
  columns={columns}
  data={filteredData}
  pagination
  paginationPerPage={5}
  paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
  highlightOnHover
  onSort={handleSort} // Pass handleSort function here
  sortServer
  // selectableRows
  // selectableRowsHighlight
  // selectableRowsNoSelectAll
  // selectableRowsVisibleOnly
  customStyles={{
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#f3f3f3",
        },
      },
    },
  }}
  customHeader={customHeader}
/>

    </div>

    <Modal open={openModal} onClose={handleModalClose} id="fileupload" >
        <div className="container">
          <h3>Import</h3>
          <hr />
          <div className="drop_box">
              
          { (pic == '') ?
                  <div>
                  <header>
                      <h4 className="choose_import">Choose the file to be imported</h4>
                    </header>
                    <p className="choose_import1">Only csv formats are supported</p>
                    <span className="choose_import2">Maximum upload file size is 5MB</span>
                    <br></br>
                  <input type="file" hidden accept=".csv" id="fileID" style={{display:"none"}} onChange={fileUploadclick}/>
                  <button class="btn choose_import3"  onClick={handleFileUpload}>Upload File</button> 
                   <br></br>
                   <a className="down_sample" href={web_url + "gallery/templates/products.csv"}>Download sample template for import</a>

                  </div> : '' } 
                  { (pic == '') ?
                  <form action="" id="uploadForm" method="post" style={{display:"none"}}> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn">Upload</button>
                  </div>
                  </form> : <form action="" id="uploadForm" method="post"> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn" type="button" onClick={handleImport}>Upload</button>
                  </div>
                  </form> }
                 
          </div>
          {errorMessage && <div className="error_message">{errorMessage}</div>}
        </div>
      </Modal>

    </div>
  );
};

export default DatatableProducts;
