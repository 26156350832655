import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";

const socket = io('https://medgrocery.riddhi.info:3005/'); // Update this with your backend URL

const Chat = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const user_id = localStorage.getItem('userid');

  useEffect(() => {
    // Fetch messages from backend
    const fetchMessages = async () => {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.get(`${api_url}/getmessages/${user_id}`);
      setMessages(response.data);
    };

    fetchMessages();

    socket.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, [user_id]);

  const sendMessage = async () => {
    if (message.trim() !== '') {
      const api_url = localStorage.getItem('api_url');
      const newMessage = { user_id, type: 'admin', message };

      setMessages((prevMessages) => [...prevMessages, newMessage]);

      try {
        await axios.post(`${api_url}/savemessage`, newMessage, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        socket.emit('sendMessage', newMessage);
      } catch (error) {
        console.error('Error sending message:', error);
      }

      setMessage('');
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">Chat History</div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={msg.type === 'admin' ? 'chat-message-admin' : 'chat-message-user'}>
            <strong>{msg.message}</strong>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
