import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "./withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Header from "./Header";
import Footer from "./Footer";
import SidebarOne from "./sidebar1";
import InnerHeader from "./InnerHeader";
import Chat from "./Chat";

class Chathistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: ''
    };
    
  }

  componentDidMount() {
    this.callgetproductsapi(this.state.prescription);
  }



  callgetproductsapi(pres, search) {
    // axios({
    //   method: 'get',
    //   url: this.state.api_url+'/getCategories',
    // }).then(res => {
    //   const data = res.data;
    //   this.setState({
    //     data: data.categories,
    //   });
    // });
  }

  

  render() {
   
    return (
      <div className="productsa">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div>
          
          <Chat />
        </div>
        
        
      </div>
    );
  }
}

export default withRouter(Chathistory);
