import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
// import Modal from 'react-modal'; // Import Modal from react-modal


const DatatableGroups = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [allSelected, setAllSelected] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url');
      const admin_id = localStorage.getItem('admin_id');
      const res = await axios.get(api_url + '/getCategories/'+admin_id);
      const data = await res.data.categories;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };
  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );

    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = a[sortField].toString().toLowerCase();
        const fieldB = b[sortField].toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setFilteredData(filteredItems);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.selector);
    setSortDirection(sortDirection);
    filterData(filterText, column.selector, sortDirection);
  };


  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };
  const web_url = localStorage.getItem('web_url');

  const navigate = useNavigate();

//   const handleDivClick = (cat_id) => {
//     localStorage.setItem("category_id", cat_id);
//     navigate("/Product", { replace: true });
//   };

 const handleDivClick = (id) => {
    localStorage.setItem("cat_id", id);
    navigate("/viewGroup", { replace: true });
    // this.props.history.push('/viewProduct');
  };

  const handleDivClick1 = (id) => {
    localStorage.setItem("cat_id", id);
    navigate("/editGroup", { replace: true });
    // this.props.history.push('/viewProduct');
  };
  const handleDivClick2 = () => {
    navigate("/createGroup", { replace: true });
    // this.props.history.push('/viewProduct');
  };

  
  
  const exportSelectedRows = () => {
    // console.log('ddd');
    const selectedRowsData = selectedRows.map(row => {
      return {
        'Category ID': row.cat_id,
        'Category Name': row.cat_name,
        'Category Description': row.cat_description,
        'Status' :row.status
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "category.xlsx");
  };

  const exportSelectedRows1 = () => {
    console.log('ddd');
    setOpenModal(true); // Open the modal
  };

  const handleModalClose = () => {
    setOpenModal(false); // Close the modal
  };

  const handleFileUpload =(e) =>{
    // this.setState({
    //     pic:e.target.files[0]
    // });
    document.getElementById("fileID").click();
  }
  const fileUploadclick = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;

    if (fileType !== "text/csv" && fileType !== "application/vnd.ms-excel" && !file.name.endsWith(".csv")) {
      setErrorMessage("Only CSV files are allowed.");
      setPic(null);
      setFilename("");
    } else {
      setErrorMessage("");
      setPic(file);
      setFilename(file.name);
    }
  }

 const handleImport=() => {
    let errors = {};
    var agentimp = new FormData();
    agentimp.append('pic', pic); 
    const admin_id = localStorage.getItem('admin_id');
    agentimp.append('admin_id', admin_id);
    const api_url = localStorage.getItem('api_url');
    axios({
        method: 'post',
        url:api_url+'/importGroups',   
        data: agentimp
             
    }).then(res => {
      alert("Category details imported successfully");
        navigate("/Groups", { replace: true });
        window.location.reload();
        })
  }

  const columns = [
    {
        name:  <input
      type="checkbox"
      onChange={(e) => handleSelectAll(e.target.checked)}
      checked={allSelected}
    />,
      cell: (row) => (
        <input
  type="checkbox"
  onChange={(e) => handleRowSelected(e.target.checked, row)}
  checked={selectedRows.map((selectedRow) => selectedRow.cat_id).includes(row.cat_id)}
/>
      ),
    },
    {
      name: 'Category ID',
      selector: row => row.cat_id,
      sortable: true,
    },
    {
      name: 'Category Name',
      selector: row => row.cat_name,
      sortable: true,
    },
    {
      name: 'Category Description',
      selector: row => row.cat_description,
      sortable: true,
    },

    {
      name: 'status',
      selector: row => row.status,
      sortable: true,
    },
    
    {
      name: 'Action',
      selector: row => row.action,
      cell: (row) => (
        <div>
      <button
        className="tableBtn"
        onClick={() => handleDivClick(row.cat_id)}
        >
        View
      </button>
      <button
        className="tableBtn"
        onClick={() => handleDivClick1(row.cat_id)}
      >
        Edit
      </button>
     
    </div>
      ),
    },
  ];

  return (
    <div>
       <div className="pro_buttons">
    <button className="import_tem" onClick={exportSelectedRows1}>IMPORT</button>
    <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
    <button className="create_tem" onClick={handleDivClick2}>CREATE</button>
</div>
        <p className="produc_de">Category</p>
    <div className="data_margin">
      
      <DataTable
  columns={columns}
  data={filteredData}
  pagination
  paginationPerPage={5}
  paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
  highlightOnHover
  onSort={handleSort}
  sortServer
  // selectableRows
  // selectableRowsHighlight
  // selectableRowsNoSelectAll
  // selectableRowsVisibleOnly
  customStyles={{
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#f3f3f3",
        },
      },
    },
  }}
/>



       
    </div>

    <Modal open={openModal} onClose={handleModalClose} id="fileupload" >
        <div className="container">
          <h3>Import</h3>
          <hr />
          <div className="drop_box">
              
          { (pic == '') ?
                  <div>
                  <header>
                      <h4 className="choose_import">Choose the file to be imported</h4>
                    </header>
                    <p className="choose_import1">Only csv formats are supported</p>
                    <span className="choose_import2">Maximum upload file size is 5MB</span>
                    <br></br>
                  <input type="file" hidden accept=".csv" id="fileID" style={{display:"none"}} onChange={fileUploadclick}/>
                  <button class="btn choose_import3"  onClick={handleFileUpload}>Upload File</button> 
                   <br></br>
                    <a className="down_sample" href={web_url + "gallery/templates/groups.csv"}>Download sample template for import</a>
                  </div> : '' }
                  { (pic == '') ?
                  <form action="" id="uploadForm" method="post" style={{display:"none"}}> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn">Upload</button>
                  </div>
                  </form> : <form action="" id="uploadForm" method="post"> 
                  <div class="form">
                  <h4>{filename}</h4>    
                  <button class="btn" type="button" onClick={handleImport}>Upload</button>
                  </div>
                  </form> }
                 
          </div>
          {errorMessage && <div className="error_message">{errorMessage}</div>}
        </div>
      </Modal>
    </div>
  );
};

export default DatatableGroups;
