import React from "react";
import { withRouter } from  "../components/withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import InnerHeader from "../components/InnerHeader";
import SidebarOne from "../components/sidebar1";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      admin_id : localStorage.getItem('admin_id'),
      data: [],
      search: '',
      prescription: 'no',
      suc_cls: '',
      errors: {},
    };
  }

  handleDivClick = (id) => {
    localStorage.setItem("user_id", id);
    this.props.navigate('/Viewusers'); // Fixed method call
  };

  componentDidMount() {
    this.callgetcustomersapi();
  }

  onChange_search = (e) => {
    this.setState({
      search: e.target.value
    }, () => this.callgetcustomersapi());
  }

  callgetcustomersapi = () => {
    let url = this.state.api_url + '/admingetCustomers';
   
      url = this.state.api_url + '/admingetCustomers/' + this.state.admin_id;
    axios.get(url)
      .then(res => {
        const data = res.data;
        this.setState({
          data: data.customers,
        });
      });
  }

  render() {
    const columns = [
      {
        name: 'Name',
        selector: row => row.fullname,
      },
      {
        name: 'Email',
        selector: row => row.email,
      },
      {
        name: 'Phone Number',
        selector: row => row.mobile,
      },
      {
        name: 'Verified',
        selector: row => 'yes',
      }, 
      {
        name: 'Actions',
        selector: row => row.action,
        cell: (row) => (
          <a
            className="tableBtn1"
            onClick={() => this.handleDivClick(row._id)} // Fixed method call
          >
            Show Details
          </a>
        ),
      }
    ];

    return (
      <div>
        <div className="productsa">
          <SidebarOne />
          <div className="row">
            <InnerHeader />
            <hr />
          </div>
          <p className="user_m">User Management</p>
          <div className="frame-3 row">
            <div className="frame-5" style={{ left: "5px" }}>
              <input type="text" placeholder="Search..." className="searchtxt" value={this.state.search} onChange={this.onChange_search} />
            </div>
          </div>
          <div className="overlap-6">
            <div className={this.state.suc_cls}>{this.state.errors.message}</div>
            <div className="group-4">
              <DataTable
                columns={columns}
                data={this.state.data}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Customers);