import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';


class InnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      admin_id:localStorage.getItem('admin_id'),
        data:[], 
        data1:[],
        data2:[],
        data3:[]    
    };     
    
  }
  componentDidMount() {   
    
    axios({
      method: 'get',
      url: this.state.api_url+'/getCounts/'+this.state.admin_id,                
      
    }).then(res => {
      const data = res.data;
     // console.log(data);
      this.setState({                         
              data: data.counts,             
            });
    });

    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsuser/'+this.state.admin_id,               
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data1: data1.counts1,             
            });
    });

    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsStores',                
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data2: data1.counts1,             
            });
    });


    axios({
      method: 'get',
      url: this.state.api_url+'/getCountsAgents',                
      
    }).then(res => {
      const data1 = res.data;
     // console.log(data);
      this.setState({                         
              data3: data1.counts1,             
            });
    });
    

}
  render() {
    return <div className="innerHeader">
    <div className="group-ih-2 row">
      <div class="col-md-2">
      <NavLink  to="/Dashboard"  activeClassName="active">
      <img className="img-3" alt="Ic poll" src={require('../assets/img/ic-poll-48px.svg').default} />
      <div className="in-header">Dashboard</div>   
      </NavLink>   
        </div>

        <div class="col-md-2">
        <NavLink  to="/Customers"  activeClassName="active">
            <img className="glyph-mail-trash"  src="/assets/img/glyph-mail-trash-2.png" alt="Glyph mail trash" />
            <div className="in-header">User Management</div>
            <div className="in-wrapper">
            <div className="button-text">{this.state.data1.customerc}</div>
            </div>
            </NavLink>     
        </div>
       
        <div class="col-md-2">
        {/* <NavLink  to="/Products"  activeClassName="active">
            <img className="img-3" alt="Glyph mail trash" src='/assets/img/glyph-mail-trash.png' />        
            <div className="in-header">Products</div>     
            <div className="in-element-status-indicators-2" style={{"left":"125px"}}>
            <div className="button-text">{this.state.data.productc}</div>
            </div>
         </NavLink>     */}
        </div>
    {/* <NavLink  to="/Customers"  activeClassName="active"><div className="in-header">Customers</div>
      </NavLink>
      <NavLink  to="/Dashboard"  activeClassName="active"><div className="in-header-2">Dashboard</div>
      </NavLink>
      <div className="in-wrapper">
        <div className="button-text">{this.state.data.customerc}</div>
      </div>
      <img className="glyph-mail-trash"  src="/assets/img/glyph-mail-trash-2.png" alt="Glyph mail trash" />
      <img className="img-3" alt="Ic poll" src={require('../assets/img/ic-poll-48px.svg').default} /> */}
    </div>
    {/* <div className="group-ih-3">
      <div className="in-header-3">Agents</div>
      <div className="in-header-2">Stores</div>
      <div className="in-element-status-indicators-2">
        <div className="button-text">{this.state.data.agentc}</div>
      </div>
      <img className="glyph-mail-trash" alt="Glyph mail trash" src="/assets/img/glyph-mail-trash-1.png" />
      <img className="img-3" alt="Ic store" src={require('../assets/img/ic-store-48px.svg').default} />
      <div className="in-overlap-group-wrapper">
        <div className="in-overlap-group-3">
          <div className="in-table-header-4">Products</div>
          <div className="in-element-status-indicators-3">
            <div className="button-text-2">{this.state.data.productc}</div>
          </div>
          <img className="img-3" alt="Glyph mail trash" src='/assets/img/glyph-mail-trash.png' />
        </div>
      </div>
    </div> */}
  </div>;
  }
}

export default InnerHeader;





