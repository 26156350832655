import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';


import ProductHierarchy from "./ProductHierarchy";

function CreateProduct(props) {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [rows, setRows] = useState([{ id: 1, value: "" }]);
  const [item_id, setItemId] = useState("");
  const [item_sku, setItemSku] = useState("");
  const [item_price, setItemPrice] = useState("");
  const [item_quantity, setItemQuantity] = useState("");
  const [item_barcode, setItemBarcode] = useState("GTIN");
  const [item_barcode_value, setItemBarcodeValue] = useState("");
  const [item_product, setItemProduct] = useState("");
  const [item_tax_ref, setItemTaxRef] = useState("");
  const [file, setFile] = useState(null);
  const [item_size, setItemSize] = useState("");
  const [item_brand, setItemBrand] = useState("");
  const [item_brand_name, setItemBrandname] = useState("");
  const [item_meterial, setItemMeterial] = useState("");
  const [item_organic, setItemOrganic] = useState("Yes");
  const [item_ingredients, setItemIngredients] = useState("");
  const [item_weight, setItemweight] = useState("");
  const [item_expiry_date, setItemExpirydate] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [item_subcategory, setItemSubcategory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAddRow = () => {
    const newRow = { id: rows.length + 1, value: "" };
    setRows([...rows, newRow]);
  };

  const handleChange = (id, e) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, value: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };
  const handleChange1 = (id, e) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, value1: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRemoveRow = id => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };
  const api_url = localStorage.getItem('api_url');
  const admin_id = localStorage.getItem('admin_id');

  const data=[];
  useEffect(() => {
    const fetchCards = async () => {
      try {
        // const response = await axios.get("/cards"); 
        // setCards(response.data); 
        axios({
            method: 'get',
            url:api_url+'/getCategories/'+admin_id,               
            
          }).then(res => {
            const data = res.data;
           // console.log(data);
           setCards(data.categories);
           setCards2(data.brand);
          });



      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCards();
  }, []); // Empty dependency array to run the effect only once on mount


    


   
    const addproductdetails = (rows) => {

      // if (!item_brand) {
      //   setErrorMessage("Product Basic Name is required.");
      //   return;
      // }

        const formData = new FormData();
        formData.append("admin_id", admin_id);
        // formData.append("item_id", item_id);
        // formData.append("item_sku", item_sku);
        formData.append("price", item_price);
        formData.append("quantity", item_quantity);
        // formData.append("barcode_type", item_barcode);
        // formData.append("barcode_value", item_barcode_value);
        formData.append("product_name", item_product);
        formData.append("tax_reference", item_tax_ref);
        formData.append("product_size", item_size);
        formData.append("brand_name", item_brand);
        formData.append("brand_id", item_brand_name);
        formData.append("meterial", item_meterial);
        formData.append("organic", item_organic);
        formData.append("ingredients", item_ingredients); 
        formData.append("expiry_date", item_expiry_date);
        formData.append("weight", item_weight);
        formData.append("category", item_subcategory);
        formData.append("pic", file);
        // formData.append("rows", rows);
        // rows.forEach(row => {
        //   formData.append(`rows[${row.id}][key]`, row.value);
        //   formData.append(`rows[${row.id}][value]`, row.value1);
        // });
       
      
        axios({
          method: "post",
          url: api_url + "/addproductdetails",
          data: formData
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        })
          .then((res) => {
            console.log("Product added successfully:", res.data);
            // Add any success handling code here
            alert("Product added successfully");
            navigate("/Products", { replace: true });
            window.location.reload();

          })
          .catch((error) => {
            console.error("Error adding product:", error);
            // Add any error handling code here
          });
      };
         
  
   
    return (
      <div className="view_pro" style={{ display: 'flex' }}>
        <div>
          <SidebarOne />
        </div>
  
        <div className="pr_names">
          <><div className="pro_names" style={{ display: 'flex' }}>
        <p className="p_name">Products </p>
        <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
      </div><hr></hr><div className="product_detail">
          <div style={{ display: 'flex' }}>

            {/* <div className="item_details">
              <div style={{ display: 'flex' }}>
                <img className="itme_img" src={require("../assets/images/hash.png")} />
                <p className="item_name">Item ID</p>
              </div>
              <input type="text" className="item_input" name="item_id" value={item_id} onChange={(e) => setItemId(e.target.value)} />
            </div> */}


            {/* <div className="stock_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/command.png")} />
                    <p className="item_name">Stock Code (SKU)</p>
                  </div>
                  <input type="text" className="item_input" name="item_sku" value={item_sku} onChange={(e) => setItemSku(e.target.value)} />
                </div>
              </div>
            </div> */}

            {/* <div className="barcode_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/maximize.png")} />
                    <p className="item_name">Barcode Type</p>
                  </div>
                  <input readOnly type="text" className="item_input" name="item_barcode" value={item_barcode} onChange={(e) => setItemBarcode(e.target.value)} />
                </div>
              </div>
            </div> */}

            {/* <div className="barvalue_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/barcode.png")} />
                    <p className="item_name">Barcode Value</p>
                  </div>
                  <input type="text" className="item_input" name="item_barcode_value" value={item_barcode_value} onChange={(e) => setItemBarcodeValue(e.target.value)} />
                </div>
              </div>
            </div> */}
            

          </div>

          <hr></hr>

          <div style={{ display: 'flex' }}>

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <img className="itme_img" src={require("../assets/images/type.png")} />
                <p className="item_name">Item Title</p>
              </div>
              <input type="text" className="item_input1" name="item_productname" value={item_product} onChange={(e) => setItemProduct(e.target.value)} />
            </div>
            <div className="tax_ref_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/hash.png")} />
                    <p className="item_name">Tax Reference Code</p>
                  </div>
                  <input type="text" className="item_input" name="item_tax_ref" value={item_tax_ref} onChange={(e) => setItemTaxRef(e.target.value)} />
                </div>
              </div>
            </div>
            <button className="itme_img1" onClick={() => addproductdetails(rows)}>Save</button>
          </div>

          <hr></hr>

<div style={{ display: 'flex' }}>

  <div className="item_details">
    <div style={{ display: 'flex' }}>
      <img className="itme_img" src={require("../assets/images/type.png")} />
      <p className="item_name">Item Price</p>
    </div>
    <input type="text" className="item_input" name="item_productname" value={item_price} onChange={(e) => setItemPrice(e.target.value)} />
  </div>
  <div className="tax_ref_details1">
    <div style={{ display: 'flex' }}>
      <div className="vl"></div>
      <div className="itme_d">
        <div style={{ display: 'flex' }}>
          <img className="itme_img" src={require("../assets/images/hash.png")} />
          <p className="item_name">Item Quantity</p>
        </div>
        <input type="text" className="item_input" name="item_tax_ref" value={item_quantity} onChange={(e) => setItemQuantity(e.target.value)} />
      </div>
    </div>
  </div>


  <div className="tax_ref_details1">
    <div style={{ display: 'flex' }}>
      <div className="vl"></div>
      <div className="itme_d">
        <div style={{ display: 'flex' }}>
          <img className="itme_img" src={require("../assets/images/hash.png")} />
          <p className="item_name">Brand Name</p>
        </div>
        <select  className="item_input1" name="item_tax_ref" value={item_brand_name} onChange={(e) => setItemBrandname(e.target.value)} >
         <option value="">Select a Brand Name</option>
          {cards2.map((card2) => (
            <option key={card2._id} value={card2._id}>
              {card2.brand_name}
            </option>
              ))}
        </select>
      </div>
    </div>
  </div>
</div>
        </div><br></br><div className="media">
          <div className="pro_de_ref">
            <p><b>Media</b></p>
            <hr />
            <br></br>
            <p className="img_and_vid"><b>Images & Videos</b></p> 
            <input type="file" onChange={handleFileChange} />

          </div>

        </div><br></br>
        
        {/* <div className="product_sepc pro_de_ref">
          <p><b>Product Specifications</b></p>
          <hr />
          <p><b>General</b></p>
          <hr />
          <button className="add_Row" onClick={handleAddRow}> + Add Specifications</button>
          <table>
            
            <tbody>
              {rows.map(row => (
                <tr key={row.id}>
                 
                  <td ><input
                      type="text"
                      className="key_row"
                      value={row.value}
                      onChange={e => handleChange(row.id, e)} /></td>

                  <td><input
                      type="text"
                      className="value_row"
                      value={row.value1}
                      onChange={e => handleChange1(row.id, e)} /></td>
                  <td>
                    <button className="remove_row" onClick={() => handleRemoveRow(row.id)}>X</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        
        <><br></br><div className="item_hi pro_de_ref">
          <p><b>Item Hierarchy</b></p>
          <hr />

          {/* <p>Category</p>
          <select name="category_name" value={item_category} onChange={(e) => setItemCategory(e.target.value)} className="item_input3">
              <option value="">Select a Category</option>
              {cards.map((card) => (
                <option key={card.cat_id} value={card.cat_id}>{card.cat_name}</option>
              ))}
            </select>

            <p>Sub category</p>

            <select name="category_name" value={item_subcategory} onChange={(e) => setItemSubcategory(e.target.value)} className="item_input3">
              <option value="">Select a Sub Category</option>
              {cards1.map((card1) => (
                <option key={card1._id} value={card1._id}>{card1.sub_cat_name}</option>
              ))}
            </select> */}


<p>Category</p>
<select
  name="category_name"
  value={item_category}
  onChange={(e) => {
    setItemCategory(e.target.value);
    setItemSubcategory(""); // Reset subcategory when category changes
    // Fetch subcategories based on the selected category
    if (e.target.value) {
      axios
        .get(api_url + "/getsubcat/" + e.target.value)
        .then((res) => {
          setCards1(res.data.subcat_details);
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        });
    } else {
      setCards1([]); // Clear subcategories when no category is selected
    }
  }}
  className="item_input3"
>
  <option value="">Select a Category</option>
  {cards.map((card) => (
    <option key={card.cat_id} value={card.cat_id}>
      {card.cat_name}
    </option>
  ))}
</select>

<p>Sub category</p>
<select
  name="subcategory_name"
  value={item_subcategory}
  onChange={(e) => setItemSubcategory(e.target.value)}
  className="item_input3"
>
  <option value="">Select a Sub Category</option>
  {cards1.map((card1) => (
    <option key={card1._id} value={card1._id}>
      {card1.sub_cat_name}
    </option>
  ))}
</select>


        </div><br></br><div className="attributes pro_de_ref">
            <p><b>Details</b></p>
            <hr />
            <table style={{ borderCollapse: 'collapse', width: '100%', 'margin-top': '-17px' }}>
              <thead>
                <tr>
                  <th className="tab_itmes">
                   <p>Container Type :</p>                         
                   <select className="item_input" name="item_size" value={item_size} onChange={(e) => setItemSize(e.target.value)} >
                    <option value="">Select Type</option>
                   <option value="Pouch">Pouch</option>
                <option value="Box">Box</option>
                <option value="Cover">Cover</option>
                       </select>
                  </th>

                  <th className="tab_itmes">
                   <p>Nutrient Content  : </p>    
                   <input type="text" className="item_input" name="item_meterial" value={item_meterial} onChange={(e) => setItemMeterial(e.target.value)} />

                  </th>
                </tr>
                <tr>
                  <th className="tab_itmes">
                    <p>Weight : </p>
                    <input type="text" className="item_input" name="item_brand" value={item_weight} onChange={(e) => setItemweight(e.target.value)} />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  </th>

                  <th className="tab_itmes">
                   <p> Ingredients  :</p> 
                   <input type="text" className="item_input2" name="item_ingredients" value={item_ingredients} onChange={(e) => setItemIngredients(e.target.value)} />

                  </th>
                </tr>
                <tr>
                  <th className="tab_itmes">
                    <p>Organic Certified</p>
                  <select type="text" className="item_input" name="item_organic" value={item_organic} onChange={(e) => setItemOrganic(e.target.value)} >
                   <option>Yes</option>
                   <option>No</option>
                    </select>

                  </th>

                  <th className="tab_itmes">
                   <p> Expiry Date :</p> 
                   <input type="date" className="item_input" name="item_expiry_date" value={item_expiry_date} onChange={(e) => setItemExpirydate(e.target.value)} />

                  </th>
                </tr>
              </thead>

            </table>
          </div><br></br></></>
        </div>
      </div>
    ); 
}

export default withRouter(CreateProduct);
