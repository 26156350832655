import React, { useState } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import SidebarOne from "./sidebar1";


function CreateScreen() {
  const [screenName, setScreenName] = useState(""); // Add screen name state
  const [screenType, setScreenType] = useState(""); // Add screen name state

  const [containers, setContainers] = useState([{
    container_name: "",
    price: "",
    position: "",
    width: "",
    height: "",
    seats: [{ seat_no: "" }]
  }]);
  
  const [errors, setErrors] = useState([]);

  const handleChange = (containerIndex, e) => {
    const { name, value } = e.target;
    const updatedContainers = containers.map((container, index) =>
      index === containerIndex ? { ...container, [name]: value } : container
    );
    setContainers(updatedContainers);
  };
  const handleScreenNameChange = (e) => {
    setScreenName(e.target.value); // Handle screen name change
  };
  const handleScreenTypeChange = (e) => {
    setScreenType(e.target.value); // Handle screen name change
  };

  const handleSeatChange = (containerIndex, seatIndex, e) => {
    const updatedContainers = containers.map((container, index) =>
      index === containerIndex
        ? {
            ...container,
            seats: container.seats.map((seat, sIndex) =>
              sIndex === seatIndex ? { ...seat, seat_no: e.target.value } : seat
            )
          }
        : container
    );
    setContainers(updatedContainers);
  };

  const addSeat = (containerIndex) => {
    const updatedContainers = containers.map((container, index) =>
      index === containerIndex
        ? { ...container, seats: [...container.seats, { seat_no: "" }] }
        : container
    );
    setContainers(updatedContainers);
  };

  const addContainer = () => {
    setContainers([
      ...containers,
      {
        container_name: "",
        price: "",
        position: "",
        width: "",
        height: "",
        seats: [{ seat_no: "" }]
      }
    ]);
  };

  const validateInputs = () => {
    let formErrors = [];
    if (!screenName) {
      formErrors.screen_name = "Screen Name is required";
    }
    if (!screenType) {
      formErrors.screen_type = "Screen Type is required";
    }
    containers.forEach((container, containerIndex) => {
      let containerErrors = {};
      if (!container.container_name) containerErrors.container_name = "Container Name is required";
      if (!container.price) containerErrors.price = "Price is required";
      if (!container.position) containerErrors.position = "Position is required";
      if (!container.width) containerErrors.width = "Width is required";
      if (!container.height) containerErrors.height = "Height is required";

      container.seats.forEach((seat, seatIndex) => {
        if (!seat.seat_no) containerErrors[`seat_no_${seatIndex}`] = `Seat No ${seatIndex + 1} is required`;
      });

      formErrors[containerIndex] = containerErrors;
    });

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (validationErrors.some((error) => Object.keys(error).length > 0)) {
      setErrors(validationErrors);
      return;
    } 
    
    const formData = {
      screen_name: screenName,
      screen_type:screenType,
      containers,
    };

    axios.post("/api/createTheatreContainers", formData)
      .then((response) => {
        alert("Containers and seats created successfully!");
      })
      .catch((error) => {
        console.error("Error creating containers and seats:", error);
      });
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
       <div>
        <SidebarOne />
      </div>
    <div className="container-wrapper">

     
      <h2>Create Screen Structure</h2>
      <form onSubmit={handleSubmit} className="form">

      <div className="form-group">
          <label>Screen Name</label>
          <input
            type="text"
            name="screen_name"
            value={screenName}
            onChange={handleScreenNameChange} // Handle screen name change
          />
          {errors.screen_name && <Alert severity="error">{errors.screen_name}</Alert>}
        </div>
        <div className="form-group">
          <label>Screen Type</label>
          <input
            type="text"
            name="screen_name"
            value={screenType}
            onChange={handleScreenTypeChange} // Handle screen name change
          />
          {errors.screen_type && <Alert severity="error">{errors.screen_type}</Alert>}
        </div>
        <br />
        {containers.map((container, containerIndex) => (
          <div key={containerIndex} className="container-form">
            <h3>Container {containerIndex + 1}</h3>
            <div className="form-group">
              <label>Container Name</label>
              <input
                type="text"
                name="container_name"
                value={container.container_name}
                onChange={(e) => handleChange(containerIndex, e)}
              />
              {errors[containerIndex]?.container_name && (
                <Alert severity="error">{errors[containerIndex].container_name}</Alert>
              )}
            </div>

            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                name="price"
                value={container.price}
                onChange={(e) => handleChange(containerIndex, e)}
              />
              {errors[containerIndex]?.price && <Alert severity="error">{errors[containerIndex].price}</Alert>}
            </div>

            <div className="form-group">
              <label>Position</label>
              <input
                type="text"
                name="position"
                value={container.position}
                onChange={(e) => handleChange(containerIndex, e)}
              />
              {errors[containerIndex]?.position && (
                <Alert severity="error">{errors[containerIndex].position}</Alert>
              )}
            </div>

            <div className="form-group">
              <label>Width</label>
              <input
                type="text"
                name="width"
                value={container.width}
                onChange={(e) => handleChange(containerIndex, e)}
              />
              {errors[containerIndex]?.width && <Alert severity="error">{errors[containerIndex].width}</Alert>}
            </div>

            <div className="form-group">
              <label>Height</label>
              <input
                type="text"
                name="height"
                value={container.height}
                onChange={(e) => handleChange(containerIndex, e)}
              />
              {errors[containerIndex]?.height && <Alert severity="error">{errors[containerIndex].height}</Alert>}
            </div>

            <h4>Seats</h4>
            {container.seats.map((seat, seatIndex) => (
              <div key={seatIndex} className="form-group">
                <label>Seat No {seatIndex + 1}</label>
                <input
                  type="text"
                  value={seat.seat_no}
                  onChange={(e) => handleSeatChange(containerIndex, seatIndex, e)}
                />
                {errors[containerIndex]?.[`seat_no_${seatIndex}`] && (
                  <Alert severity="error">{errors[containerIndex][`seat_no_${seatIndex}`]}</Alert>
                )}
              </div>
            ))}

            <button type="button" className="add-button" onClick={() => addSeat(containerIndex)}>
              Add Seat
            </button>
          </div>
        ))}

        <button type="button" className="add-button" onClick={addContainer}>
          Add Container
        </button>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
    </div>
  );
}

export default CreateScreen;
